import React from 'react';
import PropTypes from 'prop-types';

import styles from './site-title.module.css';

const SiteTitle = ( { className, size, ...props } ) => {
	return (
		<div
			className={ `${ styles.siteHeader } ${ className }` }
			style={ { fontSize: size } }
			{ ...props }
		>
			<h1 className={ styles.title }>Tina Pastore</h1>
			<h2 className={ styles.subtitle }>
				<span>Urban Designer +&nbsp;</span>
				<span>Sustainability Specialist</span>
			</h2>
		</div>
	);
};

SiteTitle.defaultProps = { className: '' };

SiteTitle.propTypes = {
	className: PropTypes.string,
	size: PropTypes.string
};

export default SiteTitle;
